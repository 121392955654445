import React, { lazy, Suspense } from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  useLocation,
} from "react-router-dom";
import Nav from "./components/Nav/Nav";
import Loading from "./components/Loading/Loading";

const Home = lazy(() => import("./pages/Home"));
const Account = lazy(() => import("./pages/Account"));
const Player = lazy(() => import("./pages/Player"));
const AddPlayer = lazy(() => import("./pages/AddPlayer"));
const AddUser = lazy(() => import("./pages/AddUser"));

const Router = () => {
  function RequireAuth({ children }) {
    const token = localStorage.getItem("token");
    let location = useLocation();

    if (!token) {
      return <Navigate to="/" state={{ from: location }} replace />;
    }
    return children;
  }
  return (
    <BrowserRouter>
      <Nav />
      <Suspense fallback={<Loading />}>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route
            exact
            path="/account"
            element={
              <RequireAuth>
                <Account />
              </RequireAuth>
            }
          />
          <Route
            exact
            path="/account/player"
            element={
              <RequireAuth>
                <Player />
              </RequireAuth>
            }
          />
          <Route
            exact
            path="/account/addplayer"
            element={
              <RequireAuth>
                <AddPlayer />
              </RequireAuth>
            }
          />
          <Route
            exact
            path="/account/adduser"
            element={
              <RequireAuth>
                <AddUser />
              </RequireAuth>
            }
          />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
};

export default Router;
