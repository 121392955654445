import styled from "styled-components";

export const Button = styled.button`
  background: #7373f2;
  border: 0.05rem solid black;
  border-radius: 0.5rem;
  color: #ffffff;
  cursor: pointer;
  font-family: "Roboto", sans-serif;
  padding: 0.5rem 1rem;
  :hover {
    background: #ffffff;
    box-shadow: 0.1rem 0.1rem 0 0 rgba(0, 0, 0, 1);
    color: #7373f2;
    transition: 0.3s;
  }
`;
