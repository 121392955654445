import React from "react";
import PropTypes from "prop-types";
import * as S from "./Button.styles";

const Button = ({
  type,
  children,
  handleClick,
  value,
  disabled,
  style,
  id,
}) => {
  return (
    <S.Button
      type={type}
      onClick={handleClick}
      value={value}
      disabled={disabled}
      style={style}
      id={id}
    >
      {children}
    </S.Button>
  );
};

Button.propTypes = {
  type: PropTypes.oneOf(["submit", "reset", "button"]),
  children: PropTypes.string.isRequired,
  handleClick: PropTypes.func,
};

Button.defaultProps = {
  type: "button",
};

export default Button;
