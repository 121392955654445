import React, { useState } from "react";
import * as S from "./Nav.styles";
import PropTypes from "prop-types";
// import { Link } from "react-router-dom";
import Button from "../Button/Button";
import Logo from "../../assets/LogoNew.png";
import { useNavigate } from "react-router-dom";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { fas } from "@fortawesome/free-solid-svg-icons";
// import { library } from "@fortawesome/fontawesome-svg-core";

// library.add(fas);

const Nav = () => {
  const [scrolled, setScrolled] = useState(false);

  const email = localStorage.getItem("email");

  // const publicLinks = [
  //   {
  //     url: "/",
  //     title: "Login",
  //     icon: "house",
  //   },
  // ];

  // const privateLinks = [
  //   {
  //     url: "/account",
  //     title: "Account",
  //     icon: "user",
  //   },
  // ];

  // const adminLinks = [
  //   {
  //     url: "/admin/admin-account",
  //     title: "Account",
  //   },
  //   {
  //     url: "/admin/admin-clinics",
  //     title: "Clinics",
  //   },
  //   {
  //     url: "/admin/admin-news",
  //     title: "News",
  //   },
  // ];

  // useEffect(() => {
  //   getUserData();
  // }, []);

  const navigate = useNavigate();
  // const admin = localStorage.getItem("admin");
  // const links = localStorage.getItem("token") ? privateLinks : publicLinks;
  return (
    <S.Nav
      scrolled={scrolled}
      onScroll={() => setScrolled(!scrolled)}
      className="navbar scrolled"
    >
      <div className="navbarWrapper">
        <div className="navmenu">
          <S.NavBrand>
            <img src={Logo} alt="LTU Basketball" />
          </S.NavBrand>
          {/* <Link
            to="/account"
            className="navbar-item"
            onClick={() => {
              // setSelectedPlayer(null);
              // window.location.reload();
            }}
          >
            <FontAwesomeIcon icon="house" />
            Home
          </Link>
          <Link to="/account/addplayer" className="navbar-item">
            <FontAwesomeIcon icon="plus" />
            Add Player
          </Link> */}
        </div>

        {
          localStorage.getItem("token") ? (
            <div className="loggedin">
              <p>Logged in as: {email}</p>
              <Button
                handleClick={() => {
                  localStorage.removeItem("token");
                  localStorage.removeItem("email");
                  navigate("/");
                  // setActive(null);
                }}
              >
                Logout
              </Button>
            </div>
          ) : null
          // <Button
          //   handleClick={() => {
          //     navigate("/login");
          //     // setActive(null);
          //   }}
          // >
          //   Login
          // </Button>
        }
      </div>
    </S.Nav>
  );
};

Nav.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      active: PropTypes.func,
    })
  ),
};

export default Nav;
